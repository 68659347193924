import {
  CLEAR_FLAGS_PARAMETROS_INTEGRACAO_BI,
  SET_PARAMETROS_INTEGRACAO_BI_INIT,
  SET_PARAMETROS_INTEGRACAO_BI_SUCCESS,
  ERROR_PARAMETROS_INTEGRACAO_BI_MSG
} from '../../actionTypes';

const INIT_STATE = {
  isUpdated: false,
  isError: false,
  loading: false,
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ERROR_PARAMETROS_INTEGRACAO_BI_MSG: {
      const { msg } = action.payload;
      return {
        ...state,
        isUpdated: false,
        msg,
        isError: true,
        loading: false
      };
    }
    case SET_PARAMETROS_INTEGRACAO_BI_INIT:
      return { ...state, isUpdated: false, loading: true };
    case SET_PARAMETROS_INTEGRACAO_BI_SUCCESS: {
      const { msg } = action.payload;
      return { ...state, isUpdated: true, msg, loading: false };
    }
    case CLEAR_FLAGS_PARAMETROS_INTEGRACAO_BI: {
      return {
        ...state,
        isUpdated: false,
        isError: false,
        loading: false,
        msg: ''
      };
    }
    default:
      return { ...state };
  }
};
