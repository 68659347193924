import {
  LIST_MOTIVO_INIT,
  LIST_MOTIVO_SUCCESS,
  CREATE_MOTIVO_INIT,
  CREATE_MOTIVO_SUCCESS,
  UPDATE_MOTIVO_INIT,
  UPDATE_MOTIVO_SUCCESS,
  DELETE_MOTIVO_INIT,
  DELETE_MOTIVO_SUCCESS,
  MOTIVO_ERROR_MSG,
  CLEAR_MOTIVO
} from '../actionTypes';

export const listMotivoInit = (data) => ({
  type: LIST_MOTIVO_INIT,
  payload: data
});

export const listMotivoSuccess = (motivos) => {
  return {
    type: LIST_MOTIVO_SUCCESS,
    payload: { motivos }
  };
};

export const createMotivoInit = (data) => ({
  type: CREATE_MOTIVO_INIT,
  payload: data
});

export const createMotivoSuccess = (data) => ({
  type: CREATE_MOTIVO_SUCCESS,
  payload: data
});

export const updateMotivoInit = (data) => ({
  type: UPDATE_MOTIVO_INIT,
  payload: data
});

export const updateMotivoSuccess = (data) => ({
  type: UPDATE_MOTIVO_SUCCESS,
  payload: data
});

export const deleteMotivoInit = (data) => ({
  type: DELETE_MOTIVO_INIT,
  payload: data
});

export const deleteMotivoSuccess = (data) => ({
  type: DELETE_MOTIVO_SUCCESS,
  payload: data
});

export const motivoErrorMsg = (data) => ({
  type: MOTIVO_ERROR_MSG,
  payload: data
});

export const clearMotivo = (data) => ({
  type: CLEAR_MOTIVO,
  payload: data
});

