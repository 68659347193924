import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SET_PARAMETROS_INTEGRACAO_BI_INIT} from '../../actionTypes';
import {
  setParametrosIntegracaoBiSuccess,
  errorParametrosIntegracaoBiMsg,
} from './actions';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { IEmpresaParametrosIntegracaoBi } from 'src/integracoes/servicos/mongo-atlas/modelos/IEmpresa';

// Atualizar dados comunicacao com o banco -------------------------
const setParametrosIntegracaoBiAsync = async ({ usuarioMongoAppServices, empId, parametrizacaoBi }: {usuarioMongoAppServices: any, empId: string, parametrizacaoBi: IEmpresaParametrosIntegracaoBi  }) => {
  const { status, mensagem } = await Empresas.atualizaParametrosIntegracaoBi(usuarioMongoAppServices, empId, parametrizacaoBi);
  if (status) {
    return { isUpdated: true, msg: mensagem };
  } else {
    return { isUpdated: false, msg: mensagem };
  }
};

// Atualizar dados funcao chamada pelo dispatch ---------------------
function* setParametrosIntegracaoBiMiddle({ payload }: {payload: any, type: any}) {
  const { usuarioMongoAppServices, empId, parametrizacaoBi } = payload;  
  try {
    const { isUpdated, msg } = yield call(setParametrosIntegracaoBiAsync, {
      usuarioMongoAppServices,
      empId,
      parametrizacaoBi
    });
    if (isUpdated) {
      yield put(setParametrosIntegracaoBiSuccess({ msg }));
    } else {
      yield put(errorParametrosIntegracaoBiMsg({ msg }));
    }
  } catch (error: any) {
    console.error(error);
    yield put(errorParametrosIntegracaoBiMsg({ msg: error.mensagem }));
  }
  
}

export function* handleSetParametrosIntegracaoBi() {
  yield takeEvery(SET_PARAMETROS_INTEGRACAO_BI_INIT, setParametrosIntegracaoBiMiddle);
}

// WATCHS ------------------------------------------------
export default function* rootSaga() {
  yield all([fork(handleSetParametrosIntegracaoBi)]);
}
