import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SET_PARAMETROS_EMBEDDED_BI_INIT, SET_PARAMETROS_INTEGRACAO_BI_INIT} from '../../actionTypes';
import {
  setParametrosEmbeddedBiSuccess,
  errorParametrosEmbeddedBiMsg,
} from './actions';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { IEmpresaParametrosEmbeddedBi } from 'src/integracoes/servicos/mongo-atlas/modelos/IEmpresa';

// Atualizar dados comunicacao com o banco -------------------------
const setParametrosEmbeddedBiAsync = async ({ usuarioMongoAppServices, empId, parametrosEmbeddedBi }: 
  {usuarioMongoAppServices: any, empId: string, parametrosEmbeddedBi: IEmpresaParametrosEmbeddedBi  }) => {
  const { status, mensagem } = await Empresas.atualizaParametrosEmbeddedBi(usuarioMongoAppServices, empId, parametrosEmbeddedBi);
  if (status) {
    return { isUpdated: true, msg: mensagem };
  } else {
    return { isUpdated: false, msg: mensagem };
  }
};

// Atualizar dados funcao chamada pelo dispatch ---------------------
function* setParametrosEmbeddedBiMiddle({ payload }: {payload: any, type: any}) {
  const { usuarioMongoAppServices, empId, parametrosEmbeddedBi } = payload;  
  try {
    const { isUpdated, msg } = yield call(setParametrosEmbeddedBiAsync, {
      usuarioMongoAppServices,
      empId,
      parametrosEmbeddedBi
    });
    if (isUpdated) {
      yield put(setParametrosEmbeddedBiSuccess({ msg }));
    } else {
      yield put(errorParametrosEmbeddedBiMsg({ msg }));
    }
  } catch (error: any) {
    console.error(error);
    yield put(errorParametrosEmbeddedBiMsg({ msg: error.mensagem }));
  }
  
}

export function* handleSetParametrosEmbeddedBi() {
  yield takeEvery(SET_PARAMETROS_EMBEDDED_BI_INIT, setParametrosEmbeddedBiMiddle);
}

// WATCHS ------------------------------------------------
export default function* rootSaga() {
  yield all([fork(handleSetParametrosEmbeddedBi)]);
}
