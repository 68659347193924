import {
  CLEAR_FLAGS_PARAMETROS_EMBEDDED_BI,
  SET_PARAMETROS_EMBEDDED_BI_INIT,
  SET_PARAMETROS_EMBEDDED_BI_SUCCESS,
  ERROR_PARAMETROS_EMBEDDED_BI_MSG
} from '../../actionTypes';

export const clearFlagsParametrosEmbeddedBi = () => ({
  type: CLEAR_FLAGS_PARAMETROS_EMBEDDED_BI,
  payload: {}
});

export const setParametrosEmbeddedBiInit = ({
  usuarioMongoAppServices,
  empId,
  parametrosEmbeddedBi
}) => ({
  type: SET_PARAMETROS_EMBEDDED_BI_INIT,
  payload: { usuarioMongoAppServices, empId, parametrosEmbeddedBi }
});

export const setParametrosEmbeddedBiSuccess = ({ msg }) => {
  return {
    type: SET_PARAMETROS_EMBEDDED_BI_SUCCESS,
    payload: { msg }
  };
};

export const errorParametrosEmbeddedBiMsg = (data) => ({
  type: ERROR_PARAMETROS_EMBEDDED_BI_MSG,
  payload: data
});
