import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import usersSagas from './users/saga';
import empresaSagas from './empresa/saga';
import paineisSagas from './paineisUsers/saga';
import metasSagas from './metas/saga';
import gruposControleSagas from './gruposControle/saga';
import fatorSagas from './fator/saga';
import campanhaSagas from './campanha/saga';
import estoqueSagas from './estoque/saga';
import curvaSagas from './curva/saga';
import categoriasSagas from './categorias/saga';
import logsSagas from './logs/saga';
import colaboradoresSagas from './colaboradores/saga';
import parametrosIntegracaoBiSagas from './parametrosBi/parametrosIntegracaoBi/saga';
import parametrosEmbeddedBiSagas from './parametrosBi/parametrosEmbeddedBi/saga';
import painelSagas from './paineis/saga';
import classificacoesSagas from './classificacoes/saga';
import motivos from './motivosStatusEmpresa/saga';


export default function* rootSaga(getState: any) {
  yield all([
    authSagas(),
    usersSagas(),
    empresaSagas(),
    paineisSagas(),
    metasSagas(),
    gruposControleSagas(),
    fatorSagas(),
    campanhaSagas(),
    estoqueSagas(),
    curvaSagas(),
    categoriasSagas(),
    logsSagas(),
    colaboradoresSagas(),
    parametrosIntegracaoBiSagas(),
    parametrosEmbeddedBiSagas(),
    painelSagas(),
    classificacoesSagas(),
    motivos()
  ]);
}
