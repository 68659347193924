import { User as RealmUser } from 'realm-web';
import { IRespostaFuncao } from "src/integracoes/modelos/funcoes";
import { IEmpresa, IEmpresaGrupo, IEmpresaGrupoControle, IEmpresaParametrosEmbeddedBi, IEmpresaParametrosIntegracaoBi } from "../modelos/IEmpresa";
import { FarmaError } from "src/integracoes/modelos/erros";
import { IEmpresaStatusEmpresa } from '../modelos/IEmpresa';
import { IFator } from '../modelos/IFator';
import { IUsuarioMongoDB } from 'src/integracoes/modelos/usuarios';
import { IEmpresaCurvaABC, IEmpresaParametroD } from '../modelos/IEmpresa';
import { ICampanha } from '../modelos/ICampanha';
import { IMeta } from '../modelos/IMeta';
import { IColaborador } from '../modelos/IColaborador';
import { IPainelDadosCompletos } from '../../powerbi/modelos/IPainelDadosCompletos';

// --------- OBTEM EMPRESA ATUAL -------//
const obtem = async (
  usuarioMongoAppServices: RealmUser,
  empId: string
): Promise<IRespostaFuncao<IEmpresa>> => {
  return usuarioMongoAppServices.functions['empresas/obtemEmpresa']({ empId })
    .then((resultado) => resultado as IRespostaFuncao<IEmpresa>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter dados da Empresa',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- OBTEM EMPRESAS -------//
const obtemEmpresas = async (
  usuarioMongoAppServices: RealmUser
): Promise<IRespostaFuncao<any>> => {
  return usuarioMongoAppServices.functions['empresas/obtemEmpresas']()
    .then((resultado) => resultado as IRespostaFuncao<any>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter Lista de Empresas',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- OBTEM USUÁRIOS EMPRESA -------//
const obtemUsuarios = async (
  usuarioMongoAppServices: RealmUser,
  empId: string,
): Promise<IRespostaFuncao<IUsuarioMongoDB[]>> => {
  return usuarioMongoAppServices.functions['empresas/usuarios/obtemUsuarios']({ empId })
    .then((resultado) => resultado as IRespostaFuncao<IUsuarioMongoDB[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter Lista de Usuários por Empresa',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- CRIA EMPRESA -------//
const cria = async (
  usuarioMongoAppServices: RealmUser,
  empresa: IEmpresa
): Promise<IRespostaFuncao<IEmpresa>> => {
  return usuarioMongoAppServices.functions['empresas/criaEmpresa'](empresa)
    .then((resultado) => resultado as IRespostaFuncao<IEmpresa>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao criar Empresa',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- ATUALIZA EMPRESA -------//
const atualiza = async (
  usuarioMongoAppServices: RealmUser,
  empresa: IEmpresa,
  status: IEmpresaStatusEmpresa,
  setStatus: boolean
): Promise<IRespostaFuncao<IEmpresa>> => {
  return usuarioMongoAppServices.functions['empresas/atualizaEmpresa']({ ...empresa, ...status, setStatus })
    .then((resultado) => resultado as IRespostaFuncao<IEmpresa>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atualizar Empresa',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- DELETA EMPRESA -------//
const deleta = async (
  usuarioMongoAppServices: RealmUser,
  empId: string
): Promise<IRespostaFuncao<any>> => {
  return usuarioMongoAppServices.functions['empresas/removeEmpresa']({ empId })
    .then((resultado) => resultado as IRespostaFuncao<any>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao deletar empresa',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- ATUALIZA CURVA ABC -------//
const atualizaCurvaAbc = async (
  usuarioMongoAppServices: RealmUser,
  empId: string,
  categorias: IEmpresaCurvaABC[],
  parametroD: IEmpresaParametroD
): Promise<IRespostaFuncao<any>> => {
  return usuarioMongoAppServices.functions['empresas/atualizaCurvaABC']({ empId, categorias, parametroD })
    .then((resultado) => resultado as IRespostaFuncao<any>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atualizar dados Curva ABC',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- ATUALIZA EXCESSO DE ESTOQUE -------//
const atualizaExcessoEstoque = async (
  usuarioMongoAppServices: RealmUser,
  empId: string,
  grupos: IEmpresaGrupo[]
): Promise<IRespostaFuncao<any>> => {
  return usuarioMongoAppServices.functions['empresas/atualizaExcessoEstoque']({ empId, grupos })
    .then((resultado) => resultado as IRespostaFuncao<any>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atualizar dados do Excesso de Estoque',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- OBTEM GRUPOS DE CONTROLE -------//
const obtemGruposControle = async (
  usuarioMongoAppServices: RealmUser,
  empId: string
): Promise<IRespostaFuncao<IEmpresaGrupoControle[]>> => {
  return usuarioMongoAppServices.functions['empresas/obtemGruposControle']({ empId })
    .then((resultado) => resultado as IRespostaFuncao<IEmpresaGrupoControle[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter Grupos de Controle',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- CRIA GRUPO DE CONTROLE -------//
const criaGrupoControle = async (
  usuarioMongoAppServices: RealmUser,
  empId: string,
  grupoControle: IEmpresaGrupoControle
): Promise<IRespostaFuncao<IEmpresaGrupoControle>> => {
  return usuarioMongoAppServices.functions['empresas/criaGrupoControle']({ empId, grupoControle })
    .then((resultado) => resultado as IRespostaFuncao<IEmpresaGrupoControle>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao criar Grupo de Controle',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- ATUALIZA GRUPO DE CONTROLE -------//
const atualizaGrupoControle = async (
  usuarioMongoAppServices: RealmUser,
  empId: string,
  grupoControle: IEmpresaGrupoControle
): Promise<IRespostaFuncao<IEmpresaGrupoControle>> => {
  return usuarioMongoAppServices.functions['empresas/atualizaGrupoControle']({ empId, grupoControle })
    .then((resultado) => resultado as IRespostaFuncao<IEmpresaGrupoControle>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atualizar Grupo de Controle',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- REMOVE GRUPO DE CONTROLE -------//
const removeGrupoControle = async (
  usuarioMongoAppServices: RealmUser,
  empId: string,
  grupoControleId: string
): Promise<IRespostaFuncao<any>> => {
  return usuarioMongoAppServices.functions['empresas/removeGrupoControle']({ empId, grupoControleId })
    .then((resultado) => resultado as IRespostaFuncao<any>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao remover Grupo de Controle',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------ OBTEM FATORES POR EMPRESA --------------//
const obtemFatores = async (
  usuarioMongoAppServices: RealmUser,
  empId: string,
): Promise<IRespostaFuncao<IFator[]>> => {

  return usuarioMongoAppServices.functions['empresas/fatores/obtemFatores']({ empId })
    .then((resultado) => resultado as IRespostaFuncao<IFator[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter Lista Fatores da Empresa',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------ OBTEM CAMPANHAS POR EMPRESA --------------//
const obtemCampanhas = async (
  usuarioMongoAppServices: RealmUser,
  empId: string,
): Promise<IRespostaFuncao<ICampanha[]>> => {
  return usuarioMongoAppServices.functions['empresas/campanhas/obtemCampanhas']({ empId })
    .then((resultado) => resultado as IRespostaFuncao<ICampanha[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter Lista de Campanhas',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------ OBTEM METAS POR EMPRESA --------------//
const obtemMetas = async (
  usuarioMongoAppServices: RealmUser,
  empId: string,
): Promise<IRespostaFuncao<IMeta[]>> => {
  return usuarioMongoAppServices.functions['empresas/metas/obtemMetas']({ empId })
    .then((resultado) => resultado as IRespostaFuncao<IMeta[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter Lista de Metas',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------ OBTEM COLABORADORES POR EMPRESA --------------//
const obtemColaboradores = async (
  usuarioMongoAppServices: RealmUser,
  empId: string,
): Promise<IRespostaFuncao<IColaborador[]>> => {
  return usuarioMongoAppServices.functions['empresas/colaboradores/obtemColaboradores']({ empId })
    .then((resultado) => resultado as IRespostaFuncao<IColaborador[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter Lista de Colaboradores',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- ATUALIZA PARAMETROS INTEGRAÇÃO POWER BI -------//
const atualizaParametrosIntegracaoBi = async (
  adminMongoDB: RealmUser,
  empId: string,
  parametrizacaoBi: IEmpresaParametrosIntegracaoBi
): Promise<IRespostaFuncao<any>> => {
  return adminMongoDB.functions['empresas/atualizaParametrosIntegracao']({ empId, parametrizacaoBi })
    .then((resultado) => resultado as IRespostaFuncao<any>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atualizar os dados de parametrização da integração com os dados dos clientes',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// --------- ATUALIZA PARAMETROS EMBEDDED POWER BI -------//
const atualizaParametrosEmbeddedBi = async (
  adminMongoDB: RealmUser,
  empId: string,
  parametrosEmbeddedBi: IEmpresaParametrosEmbeddedBi
): Promise<IRespostaFuncao<any>> => {
  return adminMongoDB.functions['empresas/atualizaParametrosEmbedded']({ empId, parametrosEmbeddedBi })
    .then((resultado) => resultado as IRespostaFuncao<any>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atualizar os dados de parametrização do PowerBI',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------ OBTEM PAINEIS DA EMPRESA ATUAL --------------//
const obtemPaineisEmpresaAtual = async (
  usuarioMongoAppServices: RealmUser,
  empId: string
): Promise<IRespostaFuncao<IPainelDadosCompletos[]>> => {
  return usuarioMongoAppServices.functions['empresas/paineis/obtemPaineis']({ empId })
    .then((resultado) => resultado as IRespostaFuncao<IPainelDadosCompletos[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter paineis da empresa e de suas lojas',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------ ATRIBUI PAINEIS A EMPRESA --------------//
const atribuiPaineis = async (
  usuarioMongoAppServices: RealmUser,
  empId: string,
  paineis: string[]
): Promise<IRespostaFuncao<string[]>> => {
  return usuarioMongoAppServices.functions['empresas/atribuiPaineis']({ empId, paineis })
    .then((resultado) => resultado as IRespostaFuncao<string[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atribuir paineis a empresa',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

export const Empresas = {
  cria,
  atualiza,
  deleta,
  obtem,
  obtemEmpresas,
  obtemUsuarios,
  obtemFatores,
  obtemCampanhas,
  obtemMetas,
  obtemColaboradores,
  atualizaCurvaAbc,
  atualizaExcessoEstoque,
  atualizaParametrosIntegracaoBi,
  atualizaParametrosEmbeddedBi,
  obtemPaineisEmpresaAtual,
  atribuiPaineis,
  obtemGruposControle,
  criaGrupoControle,
  atualizaGrupoControle,
  removeGrupoControle
}