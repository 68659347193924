import {
  LIST_MOTIVO_INIT,
  LIST_MOTIVO_SUCCESS,
  CREATE_MOTIVO_INIT,
  CREATE_MOTIVO_SUCCESS,
  UPDATE_MOTIVO_INIT,
  UPDATE_MOTIVO_SUCCESS,
  DELETE_MOTIVO_INIT,
  DELETE_MOTIVO_SUCCESS,
  MOTIVO_ERROR_MSG,
  CLEAR_MOTIVO
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  isError: false,
  motivos: [],
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_MOTIVO_INIT:
      return { ...state, loading: true, isUpdated: false };
    case LIST_MOTIVO_SUCCESS: {
      const { motivos } = action.payload;
      return {
        ...state,
        motivos,
        loading: false
      };
    }
    case CREATE_MOTIVO_INIT:
      return {
        ...state,
        loading: true,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case CREATE_MOTIVO_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isCreated: true,
      };
    }
    case UPDATE_MOTIVO_INIT:
      return {
        ...state,
        loading: true,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case UPDATE_MOTIVO_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isUpdated: true,
      };
    }
    case DELETE_MOTIVO_INIT:
      return {
        ...state,
        loading: true,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case DELETE_MOTIVO_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isDeleted: true,
      };
    }
    case MOTIVO_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, isError: true, msg };
    }
    case CLEAR_MOTIVO: {
      return {
        ...state,
        isUpdated: false,
        isCreated: false,
        isDeleted: false,
        isError: false,
        loading: false,
        msg: ''
      };
    }
    default:
      return { ...state };
  }
};
