import { User as RealmUser } from 'realm-web';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { IMotivosStatusEmpresa } from '../modelos/IMotivosStatusEmpresa';
import { FarmaError } from 'src/integracoes/modelos/erros';

// ------------------------ OBTEM LISTA MOTIVOS ------------------------ //
const obtemMotivos = async (
  usuarioMongoAppServices: RealmUser
): Promise<IRespostaFuncao<IMotivosStatusEmpresa[]>> => {
  return usuarioMongoAppServices.functions['empresas/statusEmpresas/motivos/obtemMotivos']()
    .then((resultado) => resultado as IRespostaFuncao<IMotivosStatusEmpresa[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter lista de Motivos',
        causa: e
      };
      throw new FarmaError(erro);
    });
};
  
// ------------------------ CRIAR MOTIVOS ------------------------ //
const cria = async (
    usuarioMongoAppServices: RealmUser,
    motivo: IMotivosStatusEmpresa
  ): Promise<IRespostaFuncao<IMotivosStatusEmpresa>> => {
    return usuarioMongoAppServices.functions['empresas/statusEmpresas/motivos/criaMotivo'](motivo)
      .then((resultado) => resultado as IRespostaFuncao<IMotivosStatusEmpresa>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao criar Motivo',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  
// ------------------------ ATUALIZAR MOTIVOS ------------------------ //
const atualiza = async (
    usuarioMongoAppServices: RealmUser,
    motivo: IMotivosStatusEmpresa
  ): Promise<IRespostaFuncao<IMotivosStatusEmpresa>> => {
    return usuarioMongoAppServices.functions['empresas/statusEmpresas/motivos/atualizaMotivo'](motivo)
      .then((resultado) => resultado as IRespostaFuncao<IMotivosStatusEmpresa>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao atualizar o Motivo',
          causa: e
        };
        throw new FarmaError(erro);
    });
};

// ------------------------ DELETAR MOTIVOS ------------------------ //
const deleta = async (
    usuarioMongoAppServices: RealmUser,
    motivo: {id: string}
  ): Promise<IRespostaFuncao<IMotivosStatusEmpresa>> => {
    return usuarioMongoAppServices.functions['empresas/statusEmpresas/motivos/removeMotivo'](motivo)
      .then((resultado) => resultado as IRespostaFuncao<IMotivosStatusEmpresa>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao remover o Motivo',
          causa: e
        };
        throw new FarmaError(erro);
    });
};  

export const Motivos = {
    obtemMotivos,
    cria,
    atualiza,
    deleta
}