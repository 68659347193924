import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  DELETE_MOTIVO_INIT,
  UPDATE_MOTIVO_INIT,
  CREATE_MOTIVO_INIT,
  LIST_MOTIVO_INIT
} from '../actionTypes';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { createMotivoSuccess, deleteMotivoSuccess, listMotivoSuccess, motivoErrorMsg, updateMotivoSuccess } from './actions';
import { User as RealmUser } from 'realm-web';
import { Motivos } from 'src/integracoes/servicos/mongo-atlas/funcoes/motivos';
import { IMotivosStatusEmpresa } from 'src/integracoes/servicos/mongo-atlas/modelos/IMotivosStatusEmpresa';

interface FetchListaMotivoResult {
  isError: boolean;
  motivos: IMotivosStatusEmpresa[] | null;
  errorMessage: string | null;
}

// -------------------Listar Motivo -----------------------------

const listarMotivos = async ({ usuarioMongoAppServices}: { usuarioMongoAppServices: RealmUser}) => {
  try {
    const { status, dados, mensagem } = await Motivos.obtemMotivos(usuarioMongoAppServices);
    if (status) {
      return {
        isError: !status,
        motivos: dados,
        errorMessage: null
      };
    }
    return {
      isError: true,
      motivos: null,
      errorMessage: mensagem
    };
  } catch (error: any) {

    throw new FarmaError(error);
  }
};

function* parseFetchMotivo({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices} = payload;
  try {
    const resp: FetchListaMotivoResult = yield call(listarMotivos, { usuarioMongoAppServices});
    if (!resp.isError) {
      yield put(listMotivoSuccess(resp.motivos));
    } else {
      yield put(motivoErrorMsg({ msg: resp.errorMessage }));
    }
  } catch (error: any) {
    yield put(motivoErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchListarMotivos() {
  yield takeEvery(LIST_MOTIVO_INIT, parseFetchMotivo);
}


// ---------------------------Criar Motivo---------------------------------------------------------

const createMotivoAsync = async ({ usuarioMongoAppServices, motivo}:
  { usuarioMongoAppServices: RealmUser, motivo: IMotivosStatusEmpresa }
) => {
  try {
    const { status, dados, mensagem } = await Motivos.cria(usuarioMongoAppServices, motivo);
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* createMotivoMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, motivo } = payload;
  delete motivo.id;
  try {
    const { isCreated, msg } = yield call(createMotivoAsync, {
      usuarioMongoAppServices,
      motivo: motivo
    });
    if (isCreated) {
      yield put(createMotivoSuccess({ msg }));
    } else {
      yield put(motivoErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(motivoErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchCreateMotivo() {
  yield takeEvery(CREATE_MOTIVO_INIT, createMotivoMiddle);
}

// ---------------------------atualiza Motivo---------------------------------------------------------

const atualizaMotivoAsync = async ({ usuarioMongoAppServices, motivo}:
  { usuarioMongoAppServices: RealmUser, motivo: IMotivosStatusEmpresa}
) => {
  try {
    const { status, dados, mensagem } = await Motivos.atualiza(usuarioMongoAppServices, motivo);
    if (status) {
      return {
        isUpdated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isUpdated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* atualizaMotivoMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, motivo} = payload;
  try {
    const { isUpdated, msg } = yield call(atualizaMotivoAsync, {
      usuarioMongoAppServices,
      motivo: motivo
    });
    if (isUpdated) {
      yield put(updateMotivoSuccess({ msg }));
    } else {
      yield put(motivoErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(motivoErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchAtualizaMotivo() {
  yield takeEvery(UPDATE_MOTIVO_INIT, atualizaMotivoMiddle);
}

// ---------------------------Deletar Motivo---------------------------------------------------------

const deleteMotivoAsync = async ({ usuarioMongoAppServices, motivo }:
  { usuarioMongoAppServices: RealmUser, motivo: string}
) => {
  try {
    const { status, dados, mensagem } = await Motivos.deleta(usuarioMongoAppServices, {id: motivo} );
    if (status) {
      return {
        isDeleted: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isDeleted: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* deleteMotivoMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, motivo } = payload;
  try {
    const { isDeleted, msg } = yield call(deleteMotivoAsync, {
      usuarioMongoAppServices,
      motivo: motivo
    });
    if (isDeleted) {
      yield put(deleteMotivoSuccess({ msg }));
    } else {
      yield put(motivoErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(motivoErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchDeleteMotivo() {
  yield takeEvery(DELETE_MOTIVO_INIT, deleteMotivoMiddle);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchListarMotivos),
    fork(watchCreateMotivo),
    fork(watchDeleteMotivo),
    fork(watchAtualizaMotivo)
  ]);
}
