import {
  CLEAR_FLAGS_PARAMETROS_INTEGRACAO_BI,
  SET_PARAMETROS_INTEGRACAO_BI_INIT,
  SET_PARAMETROS_INTEGRACAO_BI_SUCCESS,
  ERROR_PARAMETROS_INTEGRACAO_BI_MSG
} from '../../actionTypes';

export const clearFlagsParametrosIntegracaoBi = () => ({
  type: CLEAR_FLAGS_PARAMETROS_INTEGRACAO_BI,
  payload: {}
});

export const setParametrosIntegracaoBiInit = ({
  usuarioMongoAppServices,
  empId,
  parametrizacaoBi
}) => ({
  type: SET_PARAMETROS_INTEGRACAO_BI_INIT,
  payload: { usuarioMongoAppServices, empId, parametrizacaoBi }
});

export const setParametrosIntegracaoBiSuccess = ({ msg }) => {
  return {
    type: SET_PARAMETROS_INTEGRACAO_BI_SUCCESS,
    payload: { msg }
  };
};

export const errorParametrosIntegracaoBiMsg = (data) => ({
  type: ERROR_PARAMETROS_INTEGRACAO_BI_MSG,
  payload: data
});
